import React, { FC } from "react";

import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import Menu from "../Menu";
import styles from "./footer.module.sass";

export interface SitemapFooterProps {
  SitemapNav: CLinkPropsGraphQL[];
}

const SitemapFooter: FC<SitemapFooterProps> = ({ SitemapNav }) => {
  if (SitemapNav)
    return (
      <div className="has-text-left">
        <div className="columns">
          <div className="column is-6">
            {SitemapNav.slice(0, 4)?.map(
              (nav: CLinkPropsGraphQL, index: number) => (
                <Menu
                  item={nav}
                  key={nav.sys?.id ?? index}
                  titleClass={`${styles.anchorTag} my-2 h4`}
                  ddBodyClass="is-justify-content-left"
                />
              ),
            )}
          </div>
          <div className="column is-6">
            {SitemapNav.slice(4)?.map(
              (nav: CLinkPropsGraphQL, index: number) => (
                <Menu
                  item={nav}
                  key={nav.sys?.id ?? index}
                  titleClass={`${styles.anchorTag} my-2 h4`}
                  ddBodyClass="is-justify-content-left"
                />
              ),
            )}
          </div>
        </div>
      </div>
    );
  return null;
};

export default SitemapFooter;
