/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ParsedUrlQuery } from "querystring";
import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { camelCase } from "lodash";
import { useRouter } from "next/router";
import {
  CLinkPropsGraphQL,
  HeaderGraphQL,
  IInternalLinkPropsGraphQL,
} from "../../models/Contentful/types/siteTemplateGraphQL";
import {
  NEXT_PUBLIC_LOGIN_REDIRECT_URL,
  NEXT_PUBLIC_LOGOUT_REDIRECT_URL,
} from "../../config";

import AuthContext from "../../AppContext/AuthProvider";
import AvatarWithSymbol from "../AvatarWithSymbol";
import Button from "../Button";
import ButtonGraphQL from "../ButtonGraphQL";
import HeaderSlider from "./HeaderSlider";
import ImageComponent from "../Image";
import { LinkType } from "../../models/Contentful/types";
import { ROUTES } from "../../Constants/variables";
import SectionWrapper from "../SectionWrapper";
import Typo from "../Typo";
import User from "../../models/auth/@types";
import roleUtils from "../../utils/roleUtils";
import styles from "./header.module.sass";
import { useIsTabletViewport } from "../../hooks/useIsMobileViewport";

const ActiveNavLinkCheck = (query: ParsedUrlQuery, item: CLinkPropsGraphQL) => {
  let isActive = false;
  const linkType: LinkType = camelCase(item?.__typename) as LinkType;
  if (linkType === "internalLinks" && typeof query.slug !== "undefined") {
    const fields = item as IInternalLinkPropsGraphQL;
    isActive = query && query?.slug[0] === fields.referencePage.slug;
  }
  return isActive;
};

// eslint-disable-next-line consistent-return
const ActiveNavLinkCheckAdmin = (query: string) => {
  const isActive = true;
  const linkTypeDashboard = `${ROUTES.DASHBOARD}`;
  const linkTypeManageUser = "/manage/users";
  const linkTypeEdit = "/manage/users/edit";
  const linkTypeListProject = "/manage/projects";
  const linkTypeFeedbackRequest = "/manage/feedback-request";

  if (query === linkTypeDashboard) return isActive;
  if (query === linkTypeManageUser) return isActive;
  if (query === linkTypeEdit) return isActive;
  if (query === linkTypeListProject) return isActive;
  if (query === linkTypeFeedbackRequest) return isActive;
};

export const loadingStates = (setSSOLoggedIn: any, setRedirected: any) => {
  setSSOLoggedIn(localStorage.getItem("sso"));
  setRedirected(localStorage.getItem("redirected"));
};

const Header = (props: HeaderGraphQL) => {
  const { isTablet } = useIsTabletViewport();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [path, setPath] = useState<string>();
  const [load, setLoad] = useState<boolean>();
  const { ssoLoggedIn, redirected } = useContext(AuthContext);
  /* eslint-disable react/destructuring-assignment */
  const item1: CLinkPropsGraphQL[] | undefined = props?.navigationLinks
    ?.items[0]?.navigationLinks?.items as CLinkPropsGraphQL[];
  /* eslint-disable react/destructuring-assignment */
  const item2: CLinkPropsGraphQL[] | undefined = props?.navigationLinks
    ?.items[1]?.navigationLinks?.items as CLinkPropsGraphQL[];

  // const item3 = props.fields?.navigationLinks;
  const { query } = useRouter();
  const { appUser, logout, setFeedbackNotification, hasFeedbackNotification } =
    useContext(AuthContext);
  const isAdmin = roleUtils?.isAdmin(appUser as User);
  useEffect(() => {
    setFeedbackNotification();
    setPath(window.location.pathname);
    setLoad(true);
  }, [setFeedbackNotification]);
  if (!item1 || !item2) return <div />;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  const onLogOutClick = async () => {
    await logout();
    router.push(NEXT_PUBLIC_LOGOUT_REDIRECT_URL);
  };

  item1.forEach((item: any) => {
    if (item.referencePage.slug === "challenges") {
      item.referencePage.slug = "challenges?tab=future";
    }
  });

  // eslint-disable-next-line consistent-return
  const isAdminPanel = () => {
    const isActive = ActiveNavLinkCheckAdmin(router.pathname);
    if (isAdmin)
      return (
        <div className={styles.item}>
          <Button
            isBtn={false}
            // classes={`h6 ${styles.adminPanneltext}`}
            classes={`h6 ${isActive && "has-text-weight-bold"}`}
            medium
            linkToUrl={ROUTES.DASHBOARD}
          >
            Admin Panel
          </Button>
        </div>
      );
  };

  // eslint-disable-next-line consistent-return
  const showLogOutButton = () => {
    if (isAdmin)
      return (
        <Button
          isBtn={false}
          classes={`${styles.logOutBtn}`}
          onClick={onLogOutClick}
        >
          Log Out
        </Button>
      );
  };
  if (load) {
    if (
      path === "/sign-up/" ||
      path === "/log-in/" ||
      path?.includes("mentor-sign-up")
    ) {
      return (
        <SectionWrapper childClasses={styles.rootChildContainer}>
          <div className={styles.root}>
            <Link href="/" className={styles.logoLink}>
              <div className={`${styles.imageContainerAuth}`}>
                <ImageComponent image={props.logo?.url} />
              </div>
            </Link>
            {path.includes("mentor-sign-up") && (
              <Typo h1 classes={styles.headingAuth}>
                Mentor Sign Up
              </Typo>
            )}
            {ssoLoggedIn !== "true" &&
              redirected !== "true" &&
              path !== "/mentor-sign-up/" && (
                <Typo h1 classes={styles.headingAuth}>
                  {path === "/sign-up/" ? "Sign Up Now" : "Please Sign In"}
                </Typo>
              )}
            {redirected === "true" &&
              ssoLoggedIn !== "true" &&
              path !== "/mentor-sign-up/" && (
                <Typo h1 classes={styles.headingAuth}>
                  {path === "/sign-up/" ? "Almost Done!" : "Please Sign In"}
                </Typo>
              )}
            {ssoLoggedIn === "true" && path !== "/mentor-sign-up/" && (
              <Typo h1 classes={styles.headingAuth}>
                {path === "/sign-up/" ? "Signing Up..." : "Logging In..."}
              </Typo>
            )}
          </div>
        </SectionWrapper>
      );
    }
    return (
      <SectionWrapper>
        <div className={styles.root}>
          {isTablet ? (
            <div className={styles.sliderContainer}>
              <Button
                aria-label={"navbar burger"}
                className={`navbar-burger ${styles.burger}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </Button>
              <div className={styles.imageContainer}>
                <Link href="/" style={{ width: 75, display: "block" }}>
                  <ImageComponent
                    image={props.logo?.url}
                    width={45}
                    classes={styles.logoImage}
                  />
                </Link>
              </div>
              <div className={styles.authContainer}>
                {!appUser && (
                  <>
                    <Button
                      aria-label="Log in"
                      classes={styles.login}
                      onClick={() => router.push(ROUTES.LOGIN)}
                    >
                      Log in
                    </Button>
                    <Button
                      aria-label="Sign up"
                      theme="Filled - Lime"
                      onClick={() => router.push(ROUTES.SIGNUP)}
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </div>

              <HeaderSlider
                item1={[...item1]}
                item2={[...item2]}
                logo={props.logo}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                appUser={appUser}
              />
            </div>
          ) : (
            <>
              <Link href="/" className={styles.logoLink}>
                <div className={styles.imageContainer}>
                  <ImageComponent image={props.logo?.url} width={85} />
                </div>
              </Link>
              <div className={styles.navCenterLinksContainer}>
                {item1.map((item: any, idx) => {
                  const isActive = ActiveNavLinkCheck(query, item);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={idx} className={styles.item}>
                      <ButtonGraphQL
                        isBtn={false}
                        link={item}
                        classes={`h6 ${isActive && "has-text-weight-bold"}`}
                      />
                      {/* <div>{item?.text}</div> */}
                    </div>
                  );
                })}
                {isAdminPanel()}
              </div>

              <div className={styles.navCenterLinksContainer}>
                <div className={styles.logOutBtnContainer}>
                  {showLogOutButton()}
                </div>
                {appUser ? (
                  <Button
                    isBtn={false}
                    linkToUrl={NEXT_PUBLIC_LOGIN_REDIRECT_URL ?? ""}
                    classes="mt-2"
                  >
                    <AvatarWithSymbol
                      user={appUser}
                      type={hasFeedbackNotification ? "hasFeedback" : "none"}
                      includeName={false}
                    />
                    <Typo small classes="is-color-BlackHalf">
                      My Dashboard
                    </Typo>
                  </Button>
                ) : (
                  item2.map((item, idx) => {
                    const isActive = ActiveNavLinkCheck(query, item);
                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        className={
                          idx === 0 ? styles.loginItem : styles.signupItem
                        }
                      >
                        <ButtonGraphQL
                          isBtn={false}
                          link={item}
                          classes={`h6  ${isActive && "has-text-weight-bold"}`}
                        />
                        {/* <div>{item?.text}</div> */}
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </SectionWrapper>
    );
  }
  return (
    <SectionWrapper>
      <div className={styles.root} />
    </SectionWrapper>
  );
};

export default Header;
