/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import { camelCase } from "lodash";
import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import LinkGraphQL from "../LinkGraphQL";
import type {
  // CLinkProps,
  // CSubMenu,
  LinkType,
} from "../../models/Contentful/types";
import utils from "../../models/Contentful/utils";

export interface CMenu {
  item: CLinkPropsGraphQL;
  titleClass?: string;
  ddBodyClass?: string;
  ddChildClass?: string;
  bottomDividerClass?: string;
  hasArrow?: boolean;
  showPrivateLinks?: boolean;
  children?: string;
}

const Menu: FC<CMenu> = ({ item, ...props }) => {
  const { titleClass = "", ddBodyClass = "", showPrivateLinks = true } = props;

  const isLink: boolean = utils.isLink(camelCase(item?.__typename) as LinkType);
  if (isLink)
    return (
      <div className={`${ddBodyClass} is-flex is-justify-content-center`}>
        <LinkGraphQL
          {...(item as CLinkPropsGraphQL)}
          showPrivateLinks={showPrivateLinks}
          classes={titleClass}
        />
      </div>
    );

  return null;
};

export default Menu;
