/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import Link from "next/link";
import shortid from "shortid";
import {
  CFooterNavMenuGraphQL,
  CLinkPropsGraphQL,
  FooterGraphQL,
} from "../../models/Contentful/types/siteTemplateGraphQL";
import type {
  CSubscriptionComponent,
  FooterNavType,
} from "../../models/Contentful/types";

import ContactInformation from "./ContactInformation";
import ImageComponent from "../Image";
import MobileFooterLinks from "./MobileFooterLinks";
import SectionWrapper from "../SectionWrapper";
import SitemapFooter from "./SitemapFooter";
import Subscription from "./Subscription";
import UtilityFooter from "./UtilityFooter";
import styles from "./footer.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import utils from "../../models/Contentful/utils";

export const getFooter = (
  type: FooterNavType,
  footerNav?: CFooterNavMenuGraphQL[],
): CFooterNavMenuGraphQL | undefined => {
  if (!footerNav) return undefined;

  const filteredNav: CFooterNavMenuGraphQL[] = footerNav.filter(
    (item) => item?.navigationLinks?.items,
  );

  return filteredNav.find(
    (nav) => nav?.type === type && utils.isEmpty(nav?.navigationLinks?.items),
  );
};

const AppFooter: FC<FooterGraphQL> = (props) => {
  const { isMobile } = useIsMobileViewport();
  const { navigationLinks, logo } = props ?? {
    navigationLinks: undefined,
    logo: undefined,
  };
  const ContactNav = getFooter(
    "Footer-Contact",
    navigationLinks?.items as unknown as CFooterNavMenuGraphQL[],
  )?.navigationLinks?.items as CLinkPropsGraphQL[];

  const UtilityNav = getFooter(
    "Footer-Utility",
    navigationLinks?.items as unknown as CFooterNavMenuGraphQL[],
  )?.navigationLinks?.items as unknown as CLinkPropsGraphQL[];

  const SitemapNav = getFooter(
    "Footer-Sitemap",
    navigationLinks?.items as unknown as CFooterNavMenuGraphQL[],
  )?.navigationLinks?.items as unknown as CLinkPropsGraphQL[];

  const subscriptionNav: CSubscriptionComponent[] =
    navigationLinks?.items as unknown as unknown as CSubscriptionComponent[];

  const subscriptionData =
    subscriptionNav &&
    subscriptionNav.find((val) => val?.__typename === "SubscriptionComponent");

  if (isMobile)
    return (
      <div
        className={`is-flex is-flex-direction-column is-align-items-center is-justify-content-center py-6 ${styles.appFooter}`}
      >
        <Link href="/">
          <ImageComponent
            image={logo?.url}
            width="60px"
            height="60px"
            classes={`mb-4 ${styles.imageContainer}`}
          />
        </Link>
        <ContactInformation ContactNav={ContactNav} />
        <MobileFooterLinks SitemapNav={SitemapNav} UtilityNavV={UtilityNav} />
        {subscriptionData && (
          <Subscription {...subscriptionData} key={shortid.generate()} />
        )}
      </div>
    );

  return (
    <SectionWrapper classes={styles.appFooter}>
      <div className="columns is-multiline">
        <div className={`column is-3 ${styles.imageContainer}`}>
          <Link href="/">
            <ImageComponent image={logo?.url} width="142px" height="148px" />
          </Link>
        </div>
        <div className="column is-6">
          <SitemapFooter SitemapNav={SitemapNav} />
        </div>
        <div className="column is-2">
          <UtilityFooter UtilityNav={UtilityNav} />
        </div>
        <div className="column is-3">
          <ContactInformation ContactNav={ContactNav} />
        </div>
        <div className="column is-9">
          {subscriptionData && (
            <Subscription {...subscriptionData} key={shortid.generate()} />
          )}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default AppFooter;
