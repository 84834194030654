import React, { FC } from "react";

import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import Menu from "../Menu";
import styles from "./footer.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface UtilityFooterProps {
  UtilityNav: CLinkPropsGraphQL[];
}

const UtilityFooter: FC<UtilityFooterProps> = ({ UtilityNav }) => {
  const { isMobile } = useIsMobileViewport();

  return (
    <div
      className={` ${styles.utilityFooter} is-flex is-flex-direction-column columns`}
    >
      {UtilityNav?.map((nav: CLinkPropsGraphQL, index: number) => (
        <Menu
          item={nav}
          key={nav.sys?.id ?? index}
          titleClass={`${styles.anchorTag} my-2 ${isMobile ? "h5" : "h4 "}`}
          ddBodyClass={
            isMobile ? "is-justify-content-center" : "is-justify-content-left"
          }
        />
      ))}
    </div>
  );
};

export default UtilityFooter;
