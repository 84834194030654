import React, { FC } from "react";
import clsx from "clsx";

import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import LinkGraphQL from "../LinkGraphQL";
import styles from "./socialLink.module.sass";

export interface SocialLinkProps {
  item?: CLinkPropsGraphQL;
  isSocialLink?: boolean;
  classes?: string;
  primary?: boolean;
  children?: React.ReactNode;
}

const SocialLink: FC<SocialLinkProps> = ({ item, ...props }) => {
  const { classes = "", primary, isSocialLink = true, children } = props;
  const bodyCls = clsx(
    isSocialLink ? "is-flex is-align-items-center " : "",
    styles.socialLink,
    classes,
    primary ? styles.primary : "",
  );
  return (
    <div className={`${bodyCls}`}>
      <LinkGraphQL {...(item as CLinkPropsGraphQL)} showLabel={false}>
        {children}
      </LinkGraphQL>
      {!item && children}
      {/* {backgroundImage?.fields?.file.url && (
        <>
          <Image
            loader={utils.imageLoader}
            src={backgroundImage.fields.file.url}
            className={styles.sectionBgImg}
            alt="pic"
            layout="fill"
            objectFit="cover"
            objectPosition="top"
          />
          {hasImageBlur && <div className={styles.imageBlur} />}
        </>
      )} */}
    </div>
  );
};

export default SocialLink;
