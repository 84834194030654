import { LinkType, ContactInfoType } from "./types";

const utils = {
  isLink: (linkType: LinkType) => {
    return (
      linkType === "downloadable" ||
      linkType === "eventHandler" ||
      linkType === "externalLinks" ||
      linkType === "internalLinks"
    );
  },
  isContactInfo: (contactInfoType: ContactInfoType) => {
    return contactInfoType === "contactInformation";
  },
  isEmpty: (list: any[] | undefined) => {
    return list && list.length !== 0;
  },
};

export default utils;
