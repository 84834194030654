/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useContext } from "react";
import { AVATAR_IMAGE_PATH, DEFAULT_AVATAR } from "../../Constants/variables";
import User, { TRole } from "../../models/auth/@types";

import AuthContext from "../../AppContext/AuthProvider";
import Avatar from "../Avatar";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import styles from "./avatarWithSymbol.module.sass";

export type SymbolType =
  | "project-submission"
  | "hasFeedback"
  | "role-based"
  | "none";

export interface AvatarWithSymbolProps {
  user: Partial<User>;
  submissionMade?: boolean;
  includeName?: boolean;
  type?: SymbolType;
  horizontalName?: boolean;
  classes?: string;
}

const AvatarWithSymbol: FC<AvatarWithSymbolProps> = (props) => {
  const {
    type,
    user,
    submissionMade,
    includeName = true,
    classes,
    horizontalName = false,
  } = props;

  const { appUser } = useContext(AuthContext);
  return (
    <Avatar
      src={`${AVATAR_IMAGE_PATH}${user?.avatar?.url ?? DEFAULT_AVATAR.img}`}
      alt={user?.avatar?.name ?? "Default Profile Picture"}
      width={40}
      height={40}
      rootClass={`${styles.root} ${classes}`}
      imgContainerClass="avatar"
    >
      <div
        className={`${styles.avatarRoot} ${
          horizontalName && styles.horizontalNameSymbol
        }`}
      >
        {type === "hasFeedback" && (
          <div className={`${styles.symbol} ${styles.onlineSymbol}`} />
        )}
        {type === "project-submission" && submissionMade && (
          <div className={`${styles.symbol} ${styles.blueSymbol}`}>
            <SvgLib icon="tick" classes={styles.icon} />
          </div>
        )}
        {type === "role-based" && (
          <>
            {user.role === TRole.TEACHER && (
              <div className={`${styles.symbol} ${styles.blueSymbol}`}>
                <SvgLib icon="teacher" classes={styles.icon} />
              </div>
            )}
            {user.role === TRole.MENTOR && (
              <div className={`${styles.symbol} ${styles.greenSymbol}`}>
                <SvgLib icon="mentor" classes={styles.icon} />
              </div>
            )}
          </>
        )}
      </div>
      {includeName && (
        <Typo normal classes={`${styles.avatarName} mt-2 `}>
          {(appUser?.id === user.id && "Me") || user.full_name}
        </Typo>
      )}
    </Avatar>
  );
};

export default AvatarWithSymbol;
