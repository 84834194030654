import React, { FC } from "react";

/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import clsx from "clsx";
import styles from "./avatar.module.sass";

export interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  rootClass?: string;
  imgContainerClass?: string;
  btnClass?: string;
  isButton?: boolean;
  onClick?: () => void;
  customIcon?: React.ReactNode;
}

const Avatar: FC<AvatarProps> = (props) => {
  const {
    children,
    className,
    btnClass,
    width,
    height,
    rootClass,
    imgContainerClass,
    isButton = false,
    onClick,
    customIcon,
    ...restProps
  } = props;

  const avatarComp = (
    <div className={clsx(styles.root, rootClass)}>
      <span
        className={clsx(styles.imgRoot, imgContainerClass)}
        style={{ width, height }}
      >
        {restProps.src && (
          <img className={clsx(styles.img, className)} {...restProps} />
        )}
        {customIcon && customIcon}
      </span>
      {children}
    </div>
  );
  if (isButton)
    return (
      <button
        type="button"
        onClick={onClick}
        className={clsx(styles.btn, btnClass)}
      >
        {avatarComp}
      </button>
    );
  return avatarComp;
};

export default Avatar;
