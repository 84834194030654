/* eslint-disable import/prefer-default-export */
import User, { TRole } from "../models/auth/@types";

const roleUtils = {
  isMentor: (user: User): boolean => !!(user?.role === TRole.MENTOR),
  isStudent: (user: User): boolean => !!(user?.role === TRole.STUDENT),
  isTeacher: (user: User): boolean => !!(user?.role === TRole.TEACHER),
  isAdmin: (user: User): boolean => !!(user?.role === TRole.ADMIN),
};

export default roleUtils;
