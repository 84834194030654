/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";

import Button from "../Button";
import { COOKIE_INFO } from "../../Constants/variables";
import SectionWrapper from "../SectionWrapper";
import SvgLib from "../SvgLib";
import Typo from "../Typo";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import utils from "../../utils";
import styles from "./cookie.module.sass";

enum COOKIE_ANSWER {
  YES = "yes",
  NO = "no",
}

export const withLink = (child: React.ReactNode, link: string) => (
  <Button isBtn={false} linkToUrl={link} underline>
    {child}
  </Button>
);

const Cookie: FC = () => {
  const { isMobile } = useIsMobileViewport();
  const [active, setActive] = useState(false);
  useEffect(() => {
    const value = utils.getCookie(COOKIE_INFO);
    setActive(value !== COOKIE_ANSWER.YES && value !== COOKIE_ANSWER.NO);
  }, []);

  return (
    <div className={`${styles.root}`}>
      <SectionWrapper
        classes={clsx(styles.containerDiv, { [styles.isNotActive]: !active })}
      >
        <div className={styles.details}>
          <div className={`${styles.descContainer}`}>
            <Typo normal>
              We use cookies to ensure that we give you the best experience on
              our website. If you continue to use this site we will assume that
              you are ok with this. For more info, read our{" "}
              {withLink("privacy policy", "/privacy-policy")}
            </Typo>
          </div>
          <div
            className={` is-flex ${styles.buttonContainer} ${
              isMobile && "is-justify-content-space-between"
            }`}
          >
            <Button
              large
              onClick={() => {
                utils.setCookie(COOKIE_INFO, COOKIE_ANSWER.YES);
                setActive(false);
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </SectionWrapper>
      <button
        type="button"
        aria-label="close"
        onClick={() => setActive(false)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          border: "none",
          background: "none",
          padding: "unset",
          cursor: "pointer",
          zIndex: 40,
        }}
      >
        <SvgLib icon="cross" />
      </button>
    </div>
  );
};

export default Cookie;
