import React, { FC } from "react";

import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import Menu from "../Menu";
import UtilityFooter from "./UtilityFooter";
import styles from "./footer.module.sass";

export interface MobileFooterProps {
  SitemapNav: CLinkPropsGraphQL[];
  UtilityNavV: CLinkPropsGraphQL[];
}

const MobileFooterLinks: FC<MobileFooterProps> = ({
  SitemapNav,
  UtilityNavV,
}) => {
  return (
    <div className="columns is-variable is-6-mobile is-flex is-align-items-baseline mt-4">
      <div className="column is-6">
        {SitemapNav?.slice(0, 6)?.map(
          (nav: CLinkPropsGraphQL, index: number) => (
            <Menu
              item={nav}
              key={nav.sys?.id ?? index}
              titleClass={`${styles.anchorTag} my-2 h5`}
              ddBodyClass="is-justify-content-center"
            />
          ),
        )}
      </div>
      <div className="column is-6">
        {SitemapNav?.slice(6)?.map((nav: CLinkPropsGraphQL, index: number) => (
          <Menu
            item={nav}
            key={nav.sys?.id ?? index}
            titleClass={`${styles.anchorTag} my-2 h5`}
            ddBodyClass="is-justify-content-center"
          />
        ))}
        <UtilityFooter UtilityNav={UtilityNavV} />
      </div>
    </div>
  );
};

export default MobileFooterLinks;
