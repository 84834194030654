export const AUTH_ERRORS = {
  FIRST_NAME_REQUIRED: "Please enter your first name.",
  LAST_NAME_REQUIRED: "Please enter your last name.",
  SCHOOL_REQUIRED: "Please enter your school or organization name.",
  CITY_REQUIRED: "Please enter your town or city.",
  STATE_REQUIRED: "Please choose a state.",
  INDUSTRY_REQUIRED: "Please choose your industry.",
  COMPANY_REQUIRED: "Please enter your company name.",
  EMAIL_REQUIRED: "Please enter your email address.",
  EMAIL_VALIDATION_NOT_MATCH: "Please enter a valid email address.",
  INVITE_VALIDATION_NOT_MATCH:
    "Make sure to hit space, enter, or add a comma after each email address is added.",
  PASSWORD_REQUIRED: "Please enter a password.",
  LOGIN_PASSWORD_REQUIRED: "Please enter your password.",
  PASSWORD_VALIDATION_NOT_MATCH:
    "Password must have a minimum of 6 characters.",
  CONFIRM_PASSWORD_REQUIRED: "Please confirm your password.",
  CONFIRM_PASSWORD_NOT_MATCH: "Oops! Your passwords don’t match. Try again.",
  NAF_ACADEMY_REQUIRED: "Please select if you are a part of a NAF Academy.",
  CONFIRMATIONS_REQUIRED: "Please confirm both are true.",
  EMAIL_NOT_EXIST: "There is no account associated with this email address.",
  EMAIL_ALREADY_EXISTS:
    "There is already an account associated with this email address.",
  LOGIN_FAILED: "The password you've entered is incorrect.",
  GENERIC_ERROR: "Some error occurred. Please try again!",
  GENERIC_ERROR_500: "Something went wrong!",
  UPDATE_PASSWORD_FAILED: "Your current password is incorrect.",
  CURRENT_PASSWORD_REQUIRED: "Please enter your current password.",
  USER_BLOCKED:
    "Your account has been suspended. Please contact support for more information.",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
  RESET_PASSWORD_TOKEN_INVALID: "RESET_PASSWORD_TOKEN_INVALID",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  USER_VALIDATION_FAILING: "USER_VALIDATION_FAILING",
  VERIFICATION_TOKEN_NOT_GENERATED: "VERIFICATION_TOKEN_NOT_GENERATED",
  MENTOR_CODE_REQUIRED: "Please enter in the mentor code.",
  MENTOR_PERMISSION_DENIED: "That code is incorrect. Please try again.",
  EMAIL_ALREADY_EXISTS_CODE: "EMAIL_ALREADY_EXISTS_CODE",
  SSO_EMAIL_NOT_REGISTERED: "SSO_EMAIL_NOT_REGISTERED",
  CODE_REQUIRED: "Code is required",
  CODE_INVALID: "Must be a 6 digit number",
};

export const PROJECT_ERRORS = {
  REQUEST_MESSAGE_REQUIRED: "Message required",
  PROVIDE_FEEDBACK_REQUIRED: "Feedback required",
  PROJECT_LINK_REQUIRED: "Please add your project link",
  PROJECT_SUBMIT_SURVEY_REQUIRED: "Please fill out all survey questions.",
  PROJECT_SUBMIT_RATING_REQUIRED: "Please select a rating.",
  PROJECT_SUBMIT_CONFIRMAITON_REQUIRED: "Please confirm both.",
  CONFIRMATION_REQUIRED: "Confirmation is required",
  CANNOT_ADD_NEW_MEMBER_WITH_THIS_ROLE: "CANNOT_ADD_NEW_MEMBER_WITH_THIS_ROLE",
  MENTORS_CANNOT_JOIN_PROJECT: "MENTORS_CANNOT_JOIN_PROJECT",
  TO_MANY_INVITEES: "TO_MANY_INVITEES",
  ACTION_IN_PROCESS: "ACTION_IN_PROCESS",
  INSTANCE_NOT_FOUND: "INSTANCE_NOT_FOUND",
  ACTION_ALREADY_PERFORMED: "ACTION_ALREADY_PERFORMED",
  ALREADY_A_PARTICIPANT_IN_DIFFERENT_PROJECT_SAME_CHALLENGE:
    "ALREADY_A_PARTICIPANT_IN_DIFFERENT_PROJECT_SAME_CHALLENGE",
};

export const SKILLBUILDER_ERRORS = {
  SKILLBUILDER_SUBMISSION_REQUIRED:
    "Skillbuilder submission data is required to submit",
};

export const CUSTOM_ERRORS = {
  DEFAULT_ERROR: "DEFAULT_ERROR",
  VERIFICATION_MAIL_NOT_SENT_SUCCESSFULLY:
    "VERIFICATION_MAIL_NOT_SENT_SUCCESSFULLY",
};
