import React, { FC } from "react";
import { CLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import SocialLink from "../SocialLinks";
import SvgLib from "../SvgLib";

export interface ContactInfoProps {
  ContactNav: CLinkPropsGraphQL[];
}

const ContactInformation: FC<ContactInfoProps> = ({ ContactNav }) => {
  return (
    <div className="is-flex mt-6" style={{ gap: "24px" }}>
      {ContactNav?.map((nav, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="py-3" key={index}>
          <SocialLink item={nav} primary>
            {nav.icon && <SvgLib icon={nav.icon} classes="mr-2" />}
          </SocialLink>
        </div>
      ))}
    </div>
  );
};

export default ContactInformation;
