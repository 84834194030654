import React, { FC, useRef } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import shortid from "shortid";
import * as Yup from "yup";

import { AUTH_ERRORS } from "../../Constants/ErrorMessages";
import Button from "../Button";
import { CSubscriptionComponent } from "../../models/Contentful/types";
import { ForgotPasswordFormData } from "../../models/auth";
import { SubscribeNewsletterFormData } from "../../models/subscribe/@types";
import TextField from "../FormFields/TextField";
import Typo from "../Typo";
import { getFieldError } from "../FormFields/Utils";
import styles from "./footer.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";
import useSubscriptionAction from "./useSubscriptionAction";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(AUTH_ERRORS.EMAIL_VALIDATION_NOT_MATCH)
    .required(AUTH_ERRORS.EMAIL_REQUIRED),
});

const Subscription: FC<CSubscriptionComponent> = (
  SubscriptionNav: CSubscriptionComponent,
) => {
  const { heading, label, submitButtonText, successMessage } = SubscriptionNav;
  const { isMobile } = useIsMobileViewport();
  const { handleSubscribeEmail, subscriptionState } = useSubscriptionAction();

  const form = useRef<FormikProps<Partial<SubscribeNewsletterFormData>>>(null);
  const subscribeEmail = async (
    values: Partial<SubscribeNewsletterFormData>,
    formikHelpers: FormikHelpers<Partial<SubscribeNewsletterFormData>>,
  ) => {
    if (!values.email) return;
    handleSubscribeEmail(values, formikHelpers);
  };

  return (
    <Formik<Partial<SubscribeNewsletterFormData>>
      onSubmit={subscribeEmail}
      initialValues={{}}
      validationSchema={ValidationSchema}
      innerRef={form}
      enableReinitialize
    >
      {(formikProps: FormikProps<Partial<ForgotPasswordFormData>>) => {
        const error = getFieldError("email", formikProps);
        return (
          <form onSubmit={formikProps.handleSubmit} id="subscription_form">
            {isMobile ? (
              <div
                className={`is-flex is-flex-direction-column is-align-items-center mt-5 ${styles.subscription}`}
              >
                <div
                  className={`${styles.subscribeEmail} is-flex is-flex-direction-column`}
                >
                  {!subscriptionState ? (
                    <TextField
                      valueKey="email"
                      otherFieldProps={{
                        placeholder: "Enter Your Email",
                        onChange: formikProps.handleChange,
                        onBlur: formikProps.handleBlur,
                        className: "styles.subscribeEmail",
                      }}
                      rootClassName={styles.subscribeEmail}
                      error={error}
                      type="email"
                    />
                  ) : (
                    <div className={styles.subscribeSuccess}>
                      <Typo small>{successMessage}</Typo>
                    </div>
                  )}

                  <Button
                    type="submit"
                    theme="Transparent - White Border"
                    key={shortid.generate()}
                    normal
                    classes="mt-4 mb-5"
                  >
                    {submitButtonText || "Sign Up"}
                  </Button>
                </div>
                <Typo h4 medium classes="has-text-centered">
                  {heading}:
                </Typo>
                <Typo small classes="has-text-centered px-5">
                  {label}
                </Typo>
              </div>
            ) : (
              <div
                className={`is-flex is-flex-direction-column mt-5 ${styles.subscription}`}
              >
                <div
                  className={`${styles.subscribeEmail} is-flex is-flex-direction-row`}
                >
                  {!subscriptionState ? (
                    <TextField
                      valueKey="email"
                      otherFieldProps={{
                        placeholder: "Enter Your Email",
                        onChange: formikProps.handleChange,
                        onBlur: formikProps.handleBlur,
                        className: "styles.subscribeEmail",
                      }}
                      rootClassName={styles.subscribeEmail}
                      error={error}
                      type="email"
                    />
                  ) : (
                    <div className={styles.subscribeSuccess}>
                      <Typo small>{successMessage}</Typo>
                    </div>
                  )}

                  <Button
                    type="submit"
                    theme="Transparent - White Border"
                    key={shortid.generate()}
                    normal
                    classes="ml-2"
                  >
                    {submitButtonText || "Sign Up"}
                  </Button>
                </div>
                <span className="mt-1 is-flex">
                  {heading && (
                    <Typo classes="mr-2" bold small>
                      {heading}:
                    </Typo>
                  )}
                  {label && <Typo small>{label}</Typo>}
                </span>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Subscription;
