export const { NEXT_PUBLIC_CONTENTFUL_SPACE_ID: CONTENTFUL_SPACE_ID } =
  process.env;
export const { NEXT_PUBLIC_CONTENTFUL_ACCESS_KEY: CONTENTFUL_ACCESS_KEY } =
  process.env;
export const {
  NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_NAME: CONTENTFUL_ENVIRONMENT_NAME,
} = process.env;
export const { AWS_ACCESS_KEY_ID } = process.env;
export const { AWS_SECRET_ACCESS_KEY } = process.env;
export const { AWS_DEFAULT_REGION } = process.env;

export const { NEXT_PUBLIC_API_URL } = process.env;

export const { BUCKET } = process.env;
export const { CLOUD_FRONT } = process.env;

export const { NEXT_PUBLIC_GTAG_ID } = process.env;
export const { NEXT_PUBLIC_GTM } = process.env;
export const { NEXT_PUBLIC_CLARITY } = process.env;
export const { NEXT_PUBLIC_GOOGLE_CLIENT_URL } = process.env;
export const { NEXT_PUBLIC_GOOGLE_CLIENT_ID } = process.env;

export const { NEXT_PUBLIC_GOOGLE_SIGN_UP } = process.env;
export const { NEXT_PUBLIC_GOOGLE_LOG_IN } = process.env;

export const NEXT_PUBLIC_LOGIN_REDIRECT_URL = "dashboard";
export const NEXT_PUBLIC_LOGOUT_REDIRECT_URL = "/home";

export const { NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN } = process.env;
export const { NEXT_PUBLIC_ENVIRONMENT } = process.env;
