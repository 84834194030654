import { useState } from "react";
import { FormikHelpers } from "formik";
import SubscribeModel from "../../models/subscribe";
import { SubscribeNewsletterFormData } from "../../models/subscribe/@types";
import { logger } from "../../utils";

function useSubscriptionAction() {
  const [subscriptionState, setSubscriptionState] = useState<boolean>(false);

  const handleSubscribeEmail = async (
    values: Partial<SubscribeNewsletterFormData>,
    formikHelpers: FormikHelpers<Partial<SubscribeNewsletterFormData>>,
  ) => {
    try {
      if (!values.email) return;
      const { data } = await SubscribeModel.subscribeMail(values.email);
      if (data === "SUBSCRIBED") {
        setSubscriptionState(true);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      logger.error(error);
      throw error;
    }
  };

  return {
    handleSubscribeEmail,
    subscriptionState,
  };
}

export default useSubscriptionAction;
