/* eslint-disable import/prefer-default-export */
import { ApiResponse } from "../@types";
import { request } from "../../utils";

export const subscribeMail = async (
  data: string,
): Promise<ApiResponse<string>> => {
  return request({
    url: "subscribe",
    method: "POST",
    data: {
      email: data,
    },
  });
};
