import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { IStaticPageProps } from "../models/Contentful/types";
import Cookie from "../components/Cookie";

export interface MainLayoutProps extends IStaticPageProps<unknown> {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, site }) => {
  const [path, setPath] = useState<string>();
  useEffect(() => {
    const pathLocation = window.location.pathname;
    setPath(pathLocation);
    if (pathLocation !== "/sign-up/") {
      localStorage.removeItem("role");
    }
  }, []);
  if (path === "/sign-up/" || path === "/log-in/") {
    return (
      <>
        <Header {...site.header} />
        <div className="body">{children}</div>
      </>
    );
  }
  return (
    <>
      <Header {...site.header} />
      <div className="body">{children}</div>
      <Footer {...site.footer} />
      <Cookie />
    </>
  );
};

export default MainLayout;
