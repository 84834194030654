import React, { FC, useContext, useEffect } from "react";
import router from "next/router";
import {
  CLinkPropsGraphQL,
  ImageGraphQL,
} from "../../models/Contentful/types/siteTemplateGraphQL";
import {
  NEXT_PUBLIC_LOGIN_REDIRECT_URL,
  NEXT_PUBLIC_LOGOUT_REDIRECT_URL,
} from "../../config";

import AuthContext from "../../AppContext/AuthProvider";
import AvatarWithSymbol from "../AvatarWithSymbol";
import Button from "../Button";
import ButtonGraphQL from "../ButtonGraphQL";
import ImageComponent from "../Image";
import Typo from "../Typo";
import User from "../../models/auth/@types";
import roleUtils from "../../utils/roleUtils";
import styles from "./header.module.sass";

export interface HeaderSliderProps {
  logo: ImageGraphQL | undefined;
  item1: CLinkPropsGraphQL[];
  item2: CLinkPropsGraphQL[];
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  appUser?: User;
}

const HeaderSlider: FC<HeaderSliderProps> = (props) => {
  const { logo, item1, item2, isMenuOpen, setIsMenuOpen, appUser } = props;
  const { setFeedbackNotification, hasFeedbackNotification, logout } =
    useContext(AuthContext);
  useEffect(() => {
    setFeedbackNotification();
  }, [setFeedbackNotification]);
  const onLogOutClick = async () => {
    await logout();
    router.push(NEXT_PUBLIC_LOGOUT_REDIRECT_URL);
  };
  // eslint-disable-next-line consistent-return
  const showLogOutButton = () => {
    const isAdmin = roleUtils?.isAdmin(appUser as User);
    if (isAdmin)
      return (
        <Button
          isBtn={false}
          classes={`${styles.logOutBtn}`}
          onClick={onLogOutClick}
        >
          Log Out
        </Button>
      );
  };
  return (
    <div
      className={`${styles.headerMobileSlider} ${
        isMenuOpen ? `${styles.activeSlider} has-background-grey` : ""
      }`}
    >
      <div className={styles.imageContainer} style={{ top: 34 }}>
        <ImageComponent
          classes={styles.sliderLogo}
          image={logo?.url}
          height={100}
          width={73}
        />
        <Button
          aria-label={"navbar burger active"}
          className={`navbar-burger is-active ${styles.burger}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </Button>
      </div>

      <div className={styles.activeContainer}>
        {item1?.map((item) => (
          <div className={styles.item} key={Math.random()}>
            <ButtonGraphQL isBtn={false} link={item} classes="h6" />
          </div>
        ))}
        {appUser ? (
          <div key={Math.random()} className="mt-6">
            <Button
              isBtn={false}
              linkToUrl={NEXT_PUBLIC_LOGIN_REDIRECT_URL}
              classes="h6"
            >
              <AvatarWithSymbol
                user={appUser}
                type={hasFeedbackNotification ? "hasFeedback" : "none"}
                includeName={false}
              />
              <Typo h6 classes={`${styles.avatarName}`}>
                My Dashboard
              </Typo>
            </Button>
            <div className={`${styles.logOutBtnContainer} mt-5`}>
              {showLogOutButton()}
            </div>
          </div>
        ) : (
          item2?.map((item, idx) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className={
                idx === 0
                  ? ` ${styles.loginItem} mt-4`
                  : `${styles.signupItem} mt-6`
              }
            >
              <ButtonGraphQL isBtn={false} link={item} classes="h6" />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HeaderSlider;
